@media(min-width: 768px) {
  #device {
    position: relative;
    background: url(/galaxy2.png) no-repeat top center;
    width: 330px;
    height: 724px;
    margin: auto; }

  #content {
    position: absolute;
    top: 80px;
    left: 25px;
    width: 280px;
    height: 560px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;

    section {
      padding-top: 0!important;
      padding-bottom: 0!important; } } }

.preview-image {
  padding: 5px;
  border: 4px solid #EEE;
  margin: auto; }

.icons.spinner,
.icons.social-icon {
  font-size: 48px; }

button.button.is-square {
  border-radius: 0; }
